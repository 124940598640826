<template>
	<div class="excel-table-holder" :id="tblDivId" style="margin-top: 20px; position: relative;">
		<table class="excel-table-info">
			<tbody>
				<tr v-for="h in headings" :key="h.name">
					<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="boldme">{{h.section}}</td>
					<td data-f-name="Tahoma" data-f-sz="10">{{h.name}}</td>
				</tr>
				<tr><td></td><td></td></tr>
			</tbody>
		</table>

		<div style="width: 100%; position: relative; overflow-x: auto;">
		<table v-if="actualDataRows && actualDataRows.length > 0" class="std-tbl" data-cols-width="15">
			<thead>
				<tr v-for="(r, rind) in actualHeadRows" :key="rind">
					<td v-for="(rname, bimd) in r" :key="bimd" data-f-bold="true" class="text-center"
						data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"
						data-fill-color="FFEAEAEA"
					>
						{{rname}}
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(r, rind) in actualDataRows" :key="rind">
					<td v-for="(rval, bimd) in r" :key="bimd" :class="setTdClass(rval)"
						:data-t="getExcelDataType(rval)"
						data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"
					>
						{{numdisplayKeepExisting(rval)}}
					</td>
				</tr>
			</tbody>
		</table>
		</div>

		<table class="excel-table-info" style="display: none">
			<tr><td></td></tr>
			<tr><td></td></tr>
		</table>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "ResultTableComplexSingle",
	components: {},
	props: {
		filteritems: Object,
		table_selections: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			titleCume: [
				'Cume',
				'Exclusive Cume',
				'Avg Aud',
				'Universe Estimate',
				'Exclusive Audience (To Selected)'
			],
		}
	},
	computed: {
		tblDivId() {
			let str = ''
			for(let t of this.store.tableLayout.tables) {
				let iname = ''
				let inameItem = this.table_selections[t.id]
				if(this.store.selectionObs[t.id].length > 1) {
					let inameOb = this.store[t.id].find(item => item.id === inameItem)
					if (inameOb) iname = inameOb.name
					else if (t.id === 'surveys') {
						iname = this.getSurveyViewName(inameItem)
					}
					if (t.id === 'markets' && this.table_selections[t.id] === 0) iname = 'Grouped'
					if(str.length > 0) str += ' '
					str += iname
				}
			}
			return 'tbl_' + str
		},
		headings() {
			let arr = []
			for(let t of this.store.tableLayout.tables) {
				let iname = ''
				let inameItem = this.table_selections[t.id]
				let inameOb = this.store[t.id].find(item => item.id === inameItem)
				if(inameOb) iname = inameOb.name
				else if(t.id === 'surveys') {
					iname = this.getSurveyViewName(inameItem)
				}
				else if(t.id === 'markets' && this.table_selections[t.id] === 0) iname = 'Grouped'
				else if(t.id === 'markets' && this.table_selections[t.id] === 990) iname = 'Grouped SMBAP'
				else if(t.id === 'markets' && this.table_selections[t.id] === 991) iname = 'Grouped non-SMBAP'
				arr.push({section: t.name, name: iname})
			}
			return arr
		},
		column_items() {
			return this.store.tableLayout.columns
		},
		row_items() {
			return this.store.tableLayout.rows
		},
		actualHeadRows() {
			let rows = []
			let rowind = 0
			for(let r of this.headRows) {
				let row = []
				let cind = 0
				let livecind = 0 //actual col used
				if(!row[cind]) row[cind] = ''
				for(let rname of this.row_items) {
					if(rowind === 0) row[livecind] = rname.name
					cind++
					livecind++
				}
				for(let cell of r) {
					if(!this.dataGrid.blankCols.includes(cind)) {
						let mynameval = cell.name
						if(cell.survobs) {
							mynameval = this.getSurveyViewName(cell.id)
						}
						else if(cell.type === 'station' && cell.outsidemkt === true) {
							mynameval += " ("+this.getMarketNameFromId(cell.mktid) + ")"
						}
						else if(this.titleCume.includes(mynameval) && this.store.viewPreferences.cumePrecisionLevel === 100) {
							mynameval = mynameval + " (00s)"
						}
						else if(this.titleCume.includes(mynameval) && this.store.viewPreferences.cumePrecisionLevel === 1000) {
							mynameval = mynameval + " (000s)"
						}
						row[livecind] = mynameval
						livecind++
					}
					cind++
				}
				rows[rowind] = row
				rowind++
			}
			return rows
		},
		// valTypeMaps() {
		// 	let lob = {
		// 		markets: this.table_selections.markets,
		// 		surveys: this.table_selections.surveys,
		// 		stations: this.table_selections.stations,
		// 		dayparts: this.table_selections.dayparts,
		// 		statistics: this.table_selections.statistics,
		// 		demographics: this.table_selections.demographics,
		// 		places: this.table_selections.places,
		// 		qtrHrs: this.table_selections.qtrHrs,
		// 		audioTypes: this.table_selections.audioTypes,
		// 	};
		// 	let nLabelCols = this.row_items.length
		// 	let myColMaps = JSON.parse(JSON.stringify(this.columnMaps))
		// 	let dgblankcols = JSON.parse(JSON.stringify(this.dataGrid.blankCols))
		// 	dgblankcols.reverse()
		// 	for(let i=0; i<dgblankcols.length; i++) {
		// 		let colIndToDelete = dgblankcols[i] - nLabelCols
		// 		myColMaps.splice(colIndToDelete, 1)
		// 	}
		// 	let myRowMaps = JSON.parse(JSON.stringify(this.rowMaps))
		// 	let dgblankrows = JSON.parse(JSON.stringify(this.dataGrid.blankRows))
		// 	dgblankrows.reverse()
		// 	for(let i=0; i<dgblankrows.length; i++) {
		// 		let rowIndToDelete = dgblankrows[i]
		// 		myRowMaps.splice(rowIndToDelete, 1)
		// 	}
		// 	let lookups = {} //array of keys rowind_colind
		// 	for(let colindex=0; colindex<myColMaps.length; colindex++) {
		// 		let colMapOb = myColMaps[colindex-nLabelCols]
		// 		for(let it in colMapOb) {
		// 			if(colMapOb[it]) lob[it] = colMapOb[it]
		// 		}
		// 		for(let rowindex=0; rowindex<myRowMaps.length; rowindex++) {
		// 			let rowMapOb = myRowMaps[rowindex]
		// 			for(let it in rowMapOb) {
		// 				if(rowMapOb[it]) lob[it] = rowMapOb[it]
		// 			}
		// 			let key = rowindex + '_' + colindex
		// 			lookups[key] = lob.statistics
		// 		}
		// 	}
		// 	return lookups
		// },
		actualDataRows() {
			let rows = []
			let rowind = 0 //id in the loop
			let liverow = 0 //actual row used
			if(this.dataGrid && this.dataGrid.data) {
				for (let r of this.dataGrid.data) {
					let row = []
					let cind = 0 //id in the loop
					let livecind = 0 //actual col used
					if (!this.dataGrid.blankRows.includes(rowind)) {
						if (!row[livecind]) row[livecind] = []
						for (let cell of r) {
							if (!this.dataGrid.blankCols.includes(cind)) {
								let myval = cell
								if(this.titleCume.includes(myval) && this.store.viewPreferences.cumePrecisionLevel === 100) {
									myval = myval + " (00s)"
								}
								else if(this.titleCume.includes(myval) && this.store.viewPreferences.cumePrecisionLevel === 1000) {
									myval = myval + " (000s)"
								}
								row[livecind] = myval
								livecind++
							}
							cind++
						}
						rows[liverow] = row
						liverow++
					}
					rowind++
				}
			}
			return rows
		},
		dataGrid() {
			let grid = {data: [], blankRows: [], blankCols: []}
			let rowInd = 0
			let colInd = 0;
			let colsHaveData = []
			for(let r of this.dataRows) {
				if(!grid.data[rowInd]) grid.data[rowInd] = []
				let rowHasData = false
				let cind = 0
				colInd = 0;
				for(let titlecell of r) {
					if(titlecell && titlecell.name) {
						grid.data[rowInd][cind] = titlecell.name
						if(titlecell.survobs) {
							grid.data[rowInd][cind] = this.getSurveyViewName(titlecell.id)
						}
						else if(titlecell.type === 'station' && titlecell.outsidemkt === true) {
							grid.data[rowInd][cind] += " ("+this.getMarketNameFromId(titlecell.mktid) + ")"
						}
					}
					if(!colsHaveData.includes(colInd)) colsHaveData.push(colInd)
					cind++
					colInd++
				}
				for(let pind=0; pind<this.columnMaps.length; pind++) {
					let myval = this.getVal(rowInd, pind)
					if(myval !== false && myval !== null && myval !== undefined && (!isNaN(myval) || (isNaN(myval) && myval.length > 0 && myval.includes(":") === true))) {
						rowHasData = true
						if(!colsHaveData.includes(colInd)) colsHaveData.push(colInd)
						grid.data[rowInd][colInd] = myval
					}
					else grid.data[rowInd][colInd] = ''
					colInd++
				}
				if(rowHasData === false) grid.blankRows.push(rowInd)
				rowInd++
			}
			for(let ci=0; ci<=colInd; ci++) {
				if(!colsHaveData.includes(ci)) grid.blankCols.push(ci)
			}
			return grid
		},
		headRows() {
			let rs = []
			for(let rowInd=0; rowInd<this.column_items.length; rowInd++) { //one row per column item type
				let myrow = []
				let myColumnItemName = this.column_items[rowInd].id
				let numIterationsNeeded = this.getNumIterationsNeededCols(this.filteritems, rowInd)
				for(let i=0; i<numIterationsNeeded; i++) {
					for (let ob of this.filteritems[myColumnItemName]) {
						let myval = ob
						let numCellsNeeded = this.getNumColsNeeded(this.filteritems, rowInd)
						for (let x = 0; x < numCellsNeeded; x++) {
							myrow.push(myval)
						}
					}
				}
				rs.push(myrow)
			}
			return rs
		},
		dataRows() {
			let rs = []
			let ts = []
			let stationsAreInRows = false
			if(this.row_items.find(item => item.id === 'stations')) stationsAreInRows = true
			let marketsAreInTables = false
			if(this.store.tableLayout.tables.find(item => item.id === 'markets')) marketsAreInTables = true
			let filterOutNonMarketStations = false
			if(stationsAreInRows && marketsAreInTables && this.table_selections.markets !== 0) {
				filterOutNonMarketStations = true
			}
			for(let rowInd=0; rowInd<this.row_items.length; rowInd++) {
				let myrow = []
				let myRowItemName = this.row_items[rowInd].id
				let availObs = JSON.parse(JSON.stringify(this.filteritems)) //get a clone of the prop object
				if(filterOutNonMarketStations === true) { //remove any stations that are not in this market (but leave in Combos)
					availObs.stations = availObs.stations.filter(item => item.mktid === this.table_selections.markets || item.mktid === 0 || item.isCombo === true)
				}
				let numIterationsNeeded = this.getNumIterationsNeededRows(availObs, rowInd)
				for(let i=0; i<numIterationsNeeded; i++) {
					for (let ob of availObs[myRowItemName]) {
						let myval = ob
						let numCellsNeeded = this.getNumRowsNeeded(availObs, rowInd)
						for (let x = 0; x < numCellsNeeded; x++) {
							myrow.push(myval)
						}
					}
				}
				ts.push(myrow)
			}
			//these are currently transposed
			if(ts.length > 0) {
				for (let tr = 0; tr < ts[0].length; tr++) { //columns of first row
					if (!rs[tr]) rs[tr] = []
					for (let rowind = 0; rowind < ts.length; rowind++) {
						rs[tr].push(ts[rowind][tr])
					}
				}
			}
			return rs
		},
		columnMaps() {
			let mycolmaps = []
			if(this.headRows && this.headRows.length > 0) {
				for (let ind = 0; ind < this.headRows[0].length; ind++) { //length of first row
					let mk, su, stn, dp, dm, stc, qtr, pol, at = null
					for (let row of this.headRows) {
						let myind = 0
						for (let hr of row) {
							if (myind === ind && hr) {
								if (hr.type === 'market') mk = hr.id
								else if (hr.type === 'survey' || hr.survobs) su = hr.id
								else if (hr.type === 'station') stn = hr.id
								else if (hr.type === 'daypart') dp = hr.id
								else if (hr.type === 'statistic') stc = hr.id
								else if (hr.type === 'demographic') dm = hr.id
								else if (hr.type === 'place') pol = hr.id
								else if (hr.type === 'qtrhr') qtr = hr.id
								else if (hr.type === 'audioType') at = hr.id
							}
							myind++
						}
					}
					mycolmaps[ind] = {
						markets: mk,
						surveys: su,
						stations: stn,
						dayparts: dp,
						statistics: stc,
						demographics: dm,
						places: pol,
						qtrHrs: qtr,
						audioTypes: at,
					}
				}
			}
			return mycolmaps
		},
		rowMaps() {
			let myrowmaps = []
			for(let rowind=0; rowind<this.dataRows.length; rowind++) {
				for(let row of this.dataRows) {
					let mk, su, stn, dp, dm, stc, pol, qtr, at = null
					for(let hr of row) { //each of these is a column
						if(hr) {
							if (hr.type === 'market') mk = hr.id
							else if (hr.type === 'survey' || hr.survobs) su = hr.id
							else if (hr.type === 'station') stn = hr.id
							else if (hr.type === 'daypart') dp = hr.id
							else if (hr.type === 'statistic') stc = hr.id
							else if (hr.type === 'demographic') dm = hr.id
							else if (hr.type === 'place') pol = hr.id
							else if (hr.type === 'qtrhr') qtr = hr.id
							else if (hr.type === 'audioType') at = hr.id
						}
					}
					myrowmaps[rowind] = {
						markets: mk,
						surveys: su,
						stations: stn,
						dayparts: dp,
						statistics: stc,
						demographics: dm,
						places: pol,
						qtrHrs: qtr,
						audioTypes: at,
					}
					rowind++
				}
			}
			return myrowmaps
		},
	},
	methods: {
		getSurveyViewName(inameItem) {
			let iname = ''
			let inameOb = this.store.selectionObs['surveys'].find(item => item.id === inameItem)
			if(inameOb) iname = inameOb.name
			if(inameOb && inameOb.survobs.length === 1) iname = inameOb.survobs[0].nicename
			//check if this is an SMBAP latest-relative survey
			let smbapOnly = true
			for(let m of this.store.selectionObs.markets) {
				if(!this.store.gfkMetroMktIds.includes(m.id)) {
					smbapOnly = false
				}
			}
			if(smbapOnly === true) {
				let namelow = iname.toLowerCase().replaceAll(" ","")
				if (namelow.includes('latest')) {
					let smap = this.store.relativeLatestSMBAPMaps.find(item => item.id === namelow)
					if (smap) {
						return smap.nicename
					}
				}
			}
			else if(this.store.selectionObs.markets.length === 1) { //only one market
				let namelow = iname.toLowerCase().replaceAll(" ","")
				if (namelow.includes('latest')) {
					let latestval = null
					if(namelow.indexOf('-') === -1) {
						latestval = 0
					}
					else {
						let spl = namelow.split("-")
						latestval = parseInt(spl[1])
					}
					if(latestval >= 0) {
						let mysurvs = this.store.surveys.filter(item => item.mktid === this.store.selectionObs.markets[0].id)
						let surv = mysurvs[latestval]
						if(surv) return surv.nicename
					}
				}
			}
			return iname
		},
		getNumIterationsNeededRows(rowItems, indCount) {
			let n = 1
			for(let myind=indCount-1; myind>=0; myind--) {
				if(rowItems[this.row_items[myind]['id']]) {
					n = n * rowItems[this.row_items[myind]['id']].length
				}
			}
			return n
		},
		getNumRowsNeeded(rowItems, indCount) {
			let n = 1
			let startIndCount = indCount + 1;
			for(let myind=startIndCount; myind<this.row_items.length; myind++) {
				if(rowItems[this.row_items[myind]['id']]) {
					n = n * rowItems[this.row_items[myind]['id']].length
				}
			}
			return n
		},
		getNumIterationsNeededCols(colItems, indCount) {
			let n = 1
			for(let myind=indCount-1; myind>=0; myind--) {
				if(colItems[this.column_items[myind]['id']]) {
					n = n * colItems[this.column_items[myind]['id']].length
				}
			}
			return n
		},
		getNumColsNeeded(colItems, indCount) {
			let n = 1
			for(let myind=indCount+1; myind<this.column_items.length; myind++) {
				if(colItems[this.column_items[myind]['id']]) {
					n = n * colItems[this.column_items[myind]['id']].length
				}
			}
			return n
		},
		// getNumDecimals(rowindex, colindex) {
		// 	let key = rowindex + '_' + colindex
		// 	let valtype = ''
		// 	if(this.valTypeMaps[key]) valtype = this.valTypeMaps[key]
		// 	if(valtype.includes('shareTo')) return 1
		// 	return null
		// },
		getVal(rowindex, colindex) {
			let lob = {
				markets: this.table_selections.markets,
				surveys: this.table_selections.surveys,
				stations: this.table_selections.stations,
				dayparts: this.table_selections.dayparts,
				statistics: this.table_selections.statistics,
				demographics: this.table_selections.demographics,
				places: this.table_selections.places,
				qtrHrs: this.table_selections.qtrHrs,
				audioTypes: this.table_selections.audioTypes,
			};
			let colMapOb = this.columnMaps[colindex]
			for(let it in colMapOb) {
				if(colMapOb[it]) lob[it] = colMapOb[it]
			}
			let rowMapOb = this.rowMaps[rowindex]
			for(let it in colMapOb) {
				if(rowMapOb[it]) lob[it] = rowMapOb[it]
			}
			if(this.store.returnedData && this.store.returnedData[lob.surveys] && this.store.returnedData[lob.surveys][lob.markets] && this.store.returnedData[lob.surveys][lob.markets][lob.demographics]) {
				if(this.store.returnedData[lob.surveys][lob.markets][lob.demographics][lob.dayparts] && this.store.returnedData[lob.surveys][lob.markets][lob.demographics][lob.dayparts]['all']) {
					if(this.store.returnedData[lob.surveys][lob.markets][lob.demographics][lob.dayparts]['all'][lob.qtrHrs] && this.store.returnedData[lob.surveys][lob.markets][lob.demographics][lob.dayparts]['all'][lob.qtrHrs][lob.places]) {
						if(this.store.returnedData[lob.surveys][lob.markets][lob.demographics][lob.dayparts]['all'][lob.qtrHrs][lob.places][lob.audioTypes]) {
							let mainFiltItem = this.store.returnedData[lob.surveys][lob.markets][lob.demographics][lob.dayparts]['all'][lob.qtrHrs][lob.places][lob.audioTypes]
							if (mainFiltItem) { //still need station, statistic
								let el = mainFiltItem.stationResults.find(item => item.stationId === lob.stations)
								if (el && el[lob.statistics] !== null) {
									if (this.store.cumeStatistics.includes(lob.statistics)) {
										return el[lob.statistics] / this.store.viewPreferences.cumePrecisionLevel
									}
									return el[lob.statistics]
								}
							}
						}
					}
				}
			}
			return false
		},
		setTdClass(rval) {
			if(rval && isNaN(rval) && (rval.indexOf(":") === -1 || rval.indexOf('TSL') > -1)) { //TSL HH:MM should be treated as text, but the heading TSL should be text
				return 'text'
			}
			return ''
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.std-tbl td {
	border: 1px solid var(--greymid);
	padding: 5px
}
</style>